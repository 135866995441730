@import "layout/var";

.sale {
    position: relative;
    background-position: center;
    background-size: cover;
    
    &__left-side {
        height: 100%;
        min-height: 300px;
        background: rgba(0, 0, 0, 0.4);
        width: 250px;

        padding: 41px 10px 33px;
    }

    &__sale-text-wrapp {
        width: 240px;
        position: relative;
        left: 30%;
        margin-bottom: 17px;
    }

    &__sale-text {
        font-size: 64px;
        color: #fff;
        font-family: $mus700;
        line-height: 77px;
        position: relative;
        z-index: 2;
    }

    &__sale-line {
        position: absolute;
        bottom: 0;
        left: -10px;
    }

    &__text {
        font-size: 16px;
        color: #fff;
    }

    &__button {
        position: absolute;
        bottom: 33px;
        right: 27px;
    }
}