@import "layout/var";

.product-offer {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 40px 20px 20px 24px;

    @media (max-width: 1199px){
        padding: 30px 20px 20px;
    }

    @media (max-width: 767px){
        padding: 30px 15px 20px;
    }

    &__title {
        font-size: 24px;
        margin-bottom: 30px;
        line-height: 29px;
    }

    &__offer {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__offer-img {
        flex-shrink: 0;
    }

    &__offer-text {
        width: 100%;
        padding-left: 20px;
        color: #606060;
        font-size: 16px;
    }
}