@import "layout/var";

.order-data-form {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px){
        display: block;
    }

    &__half {
        width: 50%;

        @media (max-width: 767px){
            width: 100%;
        }

        &:nth-child(odd){
            padding-right: 16px;

            @media (max-width: 767px){
                padding: 0;
            }
        }

        &:nth-child(even){
            padding-left: 16px;

            @media (max-width: 767px){
                padding: 0;
            }
        }
    }

    &__input {
        margin-bottom: 16px;
    }
}