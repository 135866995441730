@import "layout/var";

.order-radio {
    display: flex;
    align-items: flex-start;

    @media (max-width: 767px){
        display: block;
        margin-bottom: 24px;
    }

    &__item {
        margin-right: 80px;
        margin-bottom: 16px;

        @media (max-width: 767px){
            margin-right: 0;
            margin-bottom: 7px;
        }
    }
}