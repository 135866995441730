@import "layout/var";

.stars {
    display: flex;
    align-items: center;

    &__star {
        margin-right: 3px;

        &:last-child {
            margin-right: 0;
        }
    }

}