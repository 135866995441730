@import "layout/var";

.count-and-price {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px){
        display: block;
    }


    &__counter {
        width: 150px;
        flex-shrink: 0;
        position: relative;
        display: flex;
        border-radius: 100px;
        background: #EFEFEF;
        overflow: hidden;
        
        @media (max-width: 767px){
            margin-bottom: 15px;
        }
    }

    &__counter-action {
        display: block;
        width: 100%;
        border: 0;
        background: transparent;
        position: relative;
        outline: none;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            border-style: solid;
        }

        &--less {  
            &:before {
                border-width: 6px 6px 0 6px;
                border-color: #606060 transparent transparent transparent;
            }
        }

        &--more {
            &:before {      
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent #606060 transparent;
            }
        }
    }

    &__count {
        width: 40px;
        flex-shrink: 0;
        color: #606060;
        font-size: 14px;
        text-align: center;
        position: relative;
        padding: 8px 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background: #C4C4C4;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background: #C4C4C4;
        }
    }

    &__price {
        font-size: 14px;
    }

    &__price-text {
        
    }

    &__price-value {
        color: #606060;
        font-size: 24px;
        font-family: $mus700;
        padding-left: 12px;
    }
}