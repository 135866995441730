@import "layout/var";

.select2-container--open .select2-dropdown--below{
    border-top: 1px solid #aaa;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $pink;
}

.select2-container {
    min-width: 100px;
}
.select2-container--default .select2-selection--single {
    border: none;
}

.select2-search--dropdown {
    display: none;
}

.select2-dropdown {
    border-bottom: 1px solid #aaa!important;
    border-top: 1px solid #aaa!important;
}