@import "layout/var";

.product-details {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    
    &__wrapp {
        padding: 24px 20px 34px 20px;
        max-width: 1920px;

        @media (max-width: 767px){
            padding: 24px 15px 30px;
        }
    }

    .nav-tabs {
        display: flex;
        align-items: center;
        border: none;
        margin-bottom: 15px;
        min-height: 43px;
    }
    .nav-tabs>li {
        float: none;
        margin: 0 50px 0 0;
    }

    .nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
        border: none;
        color: #000;
        border-bottom: 1px solid $pink;
        font-size: 24px;
    }

    .nav>li>a:hover, .nav>li>a:focus {
        background: transparent;
    }

    .nav-tabs>li>a {
        padding: 4px 0;
        border: none;
        border-bottom: 1px solid transparent;
        border-radius: none;
        margin: 0;
        font-size: 18px;
        color: #000;
        transition: font-size 0.3s;
    }
}