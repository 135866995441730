@import "layout/var";

.review-product {
    display: flex;

    @media (max-width: 767px){
        display: block;
    }

    &__ava {
        flex-shrink: 0;
        width: 130px;
        height: 130px;
        border: 5px solid $pink;
        border-radius: 50%;
        background-position: center;
        background-size: cover;

        @media (max-width: 767px){
            margin: 0 auto 20px auto;
        }
        
    }

    &__review {
        width: 100%;
        padding-left: 30px;

        @media (max-width: 767px){
            padding-left: 0;
        }
    }

    &__name {
        font-size: 18px;
        font-family: $mus700;
        margin-bottom: 5px;

        @media (max-width: 767px){
            text-align: center;
        }
    }

    &__stars {
        margin-bottom: 10px;
    }

    &__text {
        color: #606060;
        font-size: 18px;
    }
}