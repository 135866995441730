@import "layout/var";

.breads {
    padding: 50px 0 32px;

    @media (max-width: 991px){
        padding: 30px 0;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        margin-right: 24px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left:calc(100% + 12px);
            transform: translate(-50%,-50%);
            width: 6px;
            height: 6px;
            background: $pink;
            border-radius: 50%;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }
    }

    &__link {
        font-size: 14px;
        color: #606060;
    }
}