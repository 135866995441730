@import "layout/var";

.header-top {
    display: flex;
    align-items: center;
    padding: 7px 0;

    @media (max-width: 767px){
        flex-wrap: wrap; 
        justify-content: space-between;
    }

    &__left-side {
        width: 50%;

        @media (max-width: 767px){
            order: 1;
            width: auto;
        }
    }

    &__mid-side {
        flex-shrink: 0;

        @media (max-width: 767px){
            width: 100%;
            margin-bottom: 10px;
        }
    }

    &__right-side {
        width: 50%;

        @media (max-width: 767px){
            order: 2;
            width: auto;
        }
    }
}