@import "layout/var";

.slide-one-flex {
    display: flex;
    align-items: center;

    @media (max-width: 991px){
        padding: 45px 0 60px 0;
        align-items: flex-start;
    }

    @media (max-width: 767px){
        display: block;
    }

    &__left {
        width: 50%;
        padding-right: 20px;

        @media (max-width: 991px){
            padding-right: 0;
        }

        @media (max-width: 767px){
            width: 100%;
        }
    }

    &__right {
        width: 50%;

        @media (max-width: 991px){
            position: relative;
            top: -40px;
        }

        @media (max-width: 767px){
            top: 5px;
            width: 185px;
        }
    }

    &__right-img {
        margin: 0 auto;
    }

    &__text-1 {
        font-size: 36px;
        color: #fff;
        line-height: 43px;
        font-family: $mus700;

        @media (max-width: 1300px){
            font-size: 28px;
            line-height: inherit;
        }

        @media (max-width: 991px){
            font-size: 24px;
        }
    }

    &__text-2 {
        font-size: 64px;
        color: #fff;
        line-height: 77px;
        font-family: $mus900;
        margin-bottom: 27px;

        @media (max-width: 1300px){
            font-size: 48px;
            line-height: inherit;
        }

        @media (max-width: 991px){
            font-size: 42px;
        }
    }

    &__button {
        margin-bottom: 58px;
    }

    &__text-3-wrapp {
        display: flex;
        position: relative;
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 100%;
            height: 100%;
            width: 5000px;
            background: $pink;
            box-shadow: -15px 4px 15px rgba(0, 0, 0, 0.1);
        }
    }

    &__text-3 {
        display: flex;
        align-items: center;
        padding: 5px 39px 5px 0;
        background: $pink;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

        span {
            border-bottom: 1px solid #fff;
        }
    }

    &__text-pink {
        color: #fff;
        font-size: 14px;
    }

    &__text-3-img {
        flex-shrink: 0;
        margin-right: 16px;
    }
}