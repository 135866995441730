@import "layout/var";

.one-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;

    &__checkbox {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        left: -5000px;
        top: 0;
    }

    label {
        display: block;
        width: 16px;
        height: 16px;
        border: 2px solid $pink;
        position: relative;
        top: -1px;
        cursor: pointer;
        margin-bottom: 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 8px;
            height: 8px;
            background: $pink;
            opacity: 0;
        }
    }

    &__checkbox:checked + label {
        &:before {
            opacity: 1;
        }
    }

    &__label {
        padding-left: 16px;
        font-size: 18px;
        color: #606060;
    }

    &--radio {
        label {
            border-radius: 50%;

            &:before {
                border-radius: 50%;
            }
        }
    }
}