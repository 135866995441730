@import "layout/var";

body {
    font-family: $mus300;
    background: #f5f5f5;
}

body,html {

    &.no-scroll {
        position: relative;
        overflow: hidden;
    }
}

p {
    margin: 0;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

.round-loupe{
    border-radius: 75px;
    border: 5px solid $pink;
 }

.wrapper {
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1710px;

    @media (max-width: 1500px){
        max-width: 1239px;
    }

    @media (max-width: 767px){
        padding: 0 15px;
    }

    &--without-padd {
        padding: 0;
    }

    &--slide {
        max-width: 1440px;

        @media (max-width: 1500px){
            max-width: 1239px;
        }

        @media (max-width: 1300px){
            max-width: 991px;
        }
    }

    &--ad {
        max-width: 1440px;

        @media (max-width: 1500px){
            max-width: 1239px;
        }

        @media (max-width: 1199px){
            max-width: 991px;
        }
    }

    &--ad-two {
        max-width: 1199px;

        @media (max-width: 1199px){
            max-width: 991px;
        }
    }

    &--full-hd {
        max-width: 1920px;
    }
}