@import "layout/var";

.copyright {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px){
        display: block;
    }

    &__side {
        display: flex;

        @media (max-width: 991px){
            margin-bottom: 10px;
        }

        @media (max-width: 767px){
            display: block;
        }

        &:last-child {
            @media (max-width: 991px){
                margin-bottom: 0;
            }
        }
    }

    &__link {
        display: block;
        margin-right: 70px;
        font-size: 16px;
        color: #000;
        transition: color 0.3s;
        cursor: pointer;

        @media (max-width: 1199px){
            margin-right: 30px;
        }

        &:hover {
            color: $pink;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__text {
        margin-right: 70px;
        font-size: 16px;
        color: #000;

        @media (max-width: 1199px){
            margin-right: 30px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}