@font-face {
  font-family: 'Muslight';
  src: url("../fonts/MuseoSansCyrl-300.eot");
  src: url("../fonts/MuseoSansCyrl-300.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-300.woff") format("woff"), url("../fonts/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Musmed';
  src: url("../fonts/MuseoSansCyrl-500.eot");
  src: url("../fonts/MuseoSansCyrl-500.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-500.woff") format("woff"), url("../fonts/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Musbold';
  src: url("../fonts/MuseoSansCyrl-700.eot");
  src: url("../fonts/MuseoSansCyrl-700.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-700.woff") format("woff"), url("../fonts/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Musblack';
  src: url("../fonts/MuseoSansCyrl-900.eot");
  src: url("../fonts/MuseoSansCyrl-900.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSansCyrl-900.woff") format("woff"), url("../fonts/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Muslight", sans-serif;
  background: #f5f5f5;
}

body.no-scroll, html.no-scroll {
  position: relative;
  overflow: hidden;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.round-loupe {
  border-radius: 75px;
  border: 5px solid #F1A7B6;
}

.wrapper {
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1710px;
}

@media (max-width: 1500px) {
  .wrapper {
    max-width: 1239px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
}

.wrapper--without-padd {
  padding: 0;
}

.wrapper--slide {
  max-width: 1440px;
}

@media (max-width: 1500px) {
  .wrapper--slide {
    max-width: 1239px;
  }
}

@media (max-width: 1300px) {
  .wrapper--slide {
    max-width: 991px;
  }
}

.wrapper--ad {
  max-width: 1440px;
}

@media (max-width: 1500px) {
  .wrapper--ad {
    max-width: 1239px;
  }
}

@media (max-width: 1199px) {
  .wrapper--ad {
    max-width: 991px;
  }
}

.wrapper--ad-two {
  max-width: 1199px;
}

@media (max-width: 1199px) {
  .wrapper--ad-two {
    max-width: 991px;
  }
}

.wrapper--full-hd {
  max-width: 1920px;
}

.header {
  position: relative;
  z-index: 11;
  background: #FFFFFF;
  margin-bottom: 46px;
}

@media (max-width: 767px) {
  .header {
    margin-bottom: 0;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }
}

.header__bg {
  background: #fff;
  position: relative;
  z-index: 11;
}

@media (max-width: 767px) {
  .header__bg {
    position: static;
    background: transparent;
    z-index: 1;
  }
}

.header-top {
  display: flex;
  align-items: center;
  padding: 7px 0;
}

@media (max-width: 767px) {
  .header-top {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.header-top__left-side {
  width: 50%;
}

@media (max-width: 767px) {
  .header-top__left-side {
    order: 1;
    width: auto;
  }
}

.header-top__mid-side {
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .header-top__mid-side {
    width: 100%;
    margin-bottom: 10px;
  }
}

.header-top__right-side {
  width: 50%;
}

@media (max-width: 767px) {
  .header-top__right-side {
    order: 2;
    width: auto;
  }
}

.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #aaa;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #F1A7B6;
}

.select2-container {
  min-width: 100px;
}

.select2-container--default .select2-selection--single {
  border: none;
}

.select2-search--dropdown {
  display: none;
}

.select2-dropdown {
  border-bottom: 1px solid #aaa !important;
  border-top: 1px solid #aaa !important;
}

.logo__img {
  margin: 0 auto;
}

.logo__name {
  font-size: 24px;
  line-height: 29px;
  font-family: "Musbold", sans-serif;
  color: #F1A7B6;
  text-align: center;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .header-right {
    flex-wrap: wrap;
  }
}

.header-right__search {
  margin-right: 24px;
}

@media (max-width: 991px) {
  .header-right__search {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .header-right__search {
    display: none;
  }
}

.header-right__basket {
  margin-right: 32px;
}

@media (max-width: 767px) {
  .header-right__basket {
    margin-right: 16px;
  }
}

.header-basket {
  display: flex;
  align-items: center;
}

.header-basket__icon {
  margin-right: 12px;
  flex-shrink: 0;
}

.header-basket__count {
  flex-shrink: 0;
  margin-right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: #fff;
  border-radius: 50%;
  background: #F1A7B6;
}

.header-basket__text {
  font-size: 16px;
  display: block;
  color: #000;
}

.header-login__link {
  font-size: 16px;
  display: block;
  color: #000;
}

.navigation {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .navigation {
    box-shadow: none;
    position: static;
    top: 0;
  }
}

.navigation__tel {
  display: none;
}

.navigation--fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .navigation--fixed {
    position: static;
  }
}

.navigation--fixed .navigation__tel {
  display: block;
}

@media (max-width: 991px) {
  .navigation--fixed .navigation__tel {
    display: none;
  }
}

.header-bottom {
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 767px) {
  .header-bottom {
    display: block;
    position: static;
  }
}

.header-bottom__menu {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px 0;
}

@media (max-width: 767px) {
  .header-bottom__menu {
    border: none;
    padding: 0;
    width: 100%;
  }
}

.header-bottom__tel {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #000;
  transition: color 0.3s;
}

.header-bottom__tel:hover {
  color: #F1A7B6;
}

.header-bottom .navbar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5px;
}

.header-bottom .navbar-toggle .icon-bar {
  background: #000;
}

.menu-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .menu-list {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 15px 0;
  }
}

.menu-list__item {
  padding: 0 30px;
}

@media (max-width: 1230px) {
  .menu-list__item {
    padding: 0 20px;
  }
}

@media (max-width: 1199px) {
  .menu-list__item {
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  .menu-list__item {
    padding: 0;
    margin-bottom: 7px;
    text-align: center;
  }
}

.menu-list__link {
  font-size: 16px;
  color: #000;
  transition: color 0.3s;
}

.menu-list__link:hover {
  color: #F1A7B6;
}

.header-search {
  position: relative;
}

@media (max-width: 991px) {
  .header-search {
    display: flex;
  }
}

@media (max-width: 767px) {
  .header-search {
    display: none;
  }
}

.header-search__search-icon {
  cursor: pointer;
}

@media (max-width: 991px) {
  .header-search__search-icon {
    margin-left: auto;
  }
}

.header-search__form {
  position: absolute;
  top: -5px;
  right: 0;
  overflow: hidden;
  width: 0;
  transition: width 0.3s;
}

.header-search__form-subm {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: -5000px;
  top: 0;
}

.header-search__form-inp {
  display: block;
  width: 100%;
  background: transparent;
  padding: 3px 25px 3px 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #F1A7B6;
}

.header-search__form-close {
  display: none;
  position: absolute;
  right: 0;
  top: 3px;
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.header-search__form-close:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background: #F1A7B6;
  transform-origin: center;
  transform: translateX(-50%) rotate(45deg);
}

.header-search__form-close:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background: #F1A7B6;
  transform-origin: center;
  transform: translateY(-50%) rotate(45deg);
}

.header-search--opened .header-search__form {
  width: 200px;
}

@media (max-width: 1199px) {
  .header-search--opened .header-search__form {
    width: 150px;
  }
}

@media (max-width: 991px) {
  .header-search--opened .header-search__form {
    width: 200px;
  }
}

.header-search--opened .header-search__search-icon {
  opacity: 0;
}

.header-search--opened .header-search__form-close {
  display: block;
}

.baner-wrapp {
  position: relative;
  overflow: hidden;
}

.baner-wrapp__button {
  z-index: 9;
  position: absolute;
  bottom: 124px;
  right: 60px;
}

@media (max-width: 1300px) {
  .baner-wrapp__button {
    bottom: 60px;
    right: 40px;
  }
}

@media (max-width: 991px) {
  .baner-wrapp__button {
    bottom: 50px;
  }
}

@media (max-width: 767px) {
  .baner-wrapp__button {
    right: 15px;
  }
}

.baner__slide {
  outline: none;
  min-height: 700px;
}

@media (max-width: 1300px) {
  .baner__slide {
    min-height: 500px;
  }
}

.slide-one {
  background: #EBD6D5;
  display: flex;
  align-items: center;
}

.slide-one-flex {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .slide-one-flex {
    padding: 45px 0 60px 0;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .slide-one-flex {
    display: block;
  }
}

.slide-one-flex__left {
  width: 50%;
  padding-right: 20px;
}

@media (max-width: 991px) {
  .slide-one-flex__left {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .slide-one-flex__left {
    width: 100%;
  }
}

.slide-one-flex__right {
  width: 50%;
}

@media (max-width: 991px) {
  .slide-one-flex__right {
    position: relative;
    top: -40px;
  }
}

@media (max-width: 767px) {
  .slide-one-flex__right {
    top: 5px;
    width: 185px;
  }
}

.slide-one-flex__right-img {
  margin: 0 auto;
}

.slide-one-flex__text-1 {
  font-size: 36px;
  color: #fff;
  line-height: 43px;
  font-family: "Musbold", sans-serif;
}

@media (max-width: 1300px) {
  .slide-one-flex__text-1 {
    font-size: 28px;
    line-height: inherit;
  }
}

@media (max-width: 991px) {
  .slide-one-flex__text-1 {
    font-size: 24px;
  }
}

.slide-one-flex__text-2 {
  font-size: 64px;
  color: #fff;
  line-height: 77px;
  font-family: "Musblack", sans-serif;
  margin-bottom: 27px;
}

@media (max-width: 1300px) {
  .slide-one-flex__text-2 {
    font-size: 48px;
    line-height: inherit;
  }
}

@media (max-width: 991px) {
  .slide-one-flex__text-2 {
    font-size: 42px;
  }
}

.slide-one-flex__button {
  margin-bottom: 58px;
}

.slide-one-flex__text-3-wrapp {
  display: flex;
  position: relative;
}

.slide-one-flex__text-3-wrapp:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 5000px;
  background: #F1A7B6;
  box-shadow: -15px 4px 15px rgba(0, 0, 0, 0.1);
}

.slide-one-flex__text-3 {
  display: flex;
  align-items: center;
  padding: 5px 39px 5px 0;
  background: #F1A7B6;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.slide-one-flex__text-3 span {
  border-bottom: 1px solid #fff;
}

.slide-one-flex__text-pink {
  color: #fff;
  font-size: 14px;
}

.slide-one-flex__text-3-img {
  flex-shrink: 0;
  margin-right: 16px;
}

.button-wrapp {
  display: flex;
}

.button-wrapp__button {
  border: none;
  display: block;
  position: relative;
  font-size: 16px;
  transition: color 0.3s, background-color 0.3s;
  text-align: left;
}

.button-wrapp__button:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%);
  height: 1px;
  width: 60px;
  transition: background-color 0.3s;
}

.button-wrapp__button--pink {
  padding: 14px 94px 14px 29px;
  color: #fff;
  background: #F1A7B6;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);
}

.button-wrapp__button--pink:before {
  background: #fff;
}

.button-wrapp__button--pink:hover {
  color: #606060;
  background: #fff;
}

.button-wrapp__button--pink:hover:before {
  background: #F1A7B6;
}

.button-wrapp__button--white {
  padding: 16px 98px 16px 32px;
  color: #606060;
  background: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}

.button-wrapp__button--white:before {
  background: #F1A7B6;
}

.button-wrapp__button--white:hover {
  background-color: #F1A7B6;
  color: #fff;
}

.button-wrapp__button--white:hover:before {
  background-color: #fff;
}

.button-wrapp__button--less-shadow {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
}

.button-wrapp__button--min-width {
  min-width: 231px;
}

.button-consult {
  display: block;
}

.button-consult__img {
  margin: 0 auto 13px auto;
}

.button-consult__text {
  text-align: center;
  display: block;
  font-size: 14px;
  font-family: "Musbold", sans-serif;
  color: #fff;
}

.slick-arrow {
  display: block;
  position: absolute;
  font-size: 0;
  background: transparent;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 24px;
  border: none;
  padding: 0;
  outline: none;
  z-index: 9;
}

.slick-arrow:before {
  content: "";
  display: block;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
}

.slick-arrow.slick-prev {
  left: 60px;
}

@media (max-width: 1600px) {
  .slick-arrow.slick-prev {
    left: 20px;
  }
}

.slick-arrow.slick-prev:before {
  border-width: 12px 12px 12px 0;
  border-color: transparent #ffffff transparent transparent;
}

.slick-arrow.slick-next {
  right: 60px;
}

@media (max-width: 1600px) {
  .slick-arrow.slick-next {
    right: 20px;
  }
}

.slick-arrow.slick-next:before {
  border-width: 12px 0 12px 12px;
  border-color: transparent transparent transparent #ffffff;
}

.slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex !important;
  position: absolute;
  left: 50%;
  bottom: 78px;
  transform: translateX(-50%);
  z-index: 9;
}

@media (max-width: 1300px) {
  .slick-dots {
    bottom: 40px;
  }
}

@media (max-width: 991px) {
  .slick-dots {
    bottom: 20px;
  }
}

.slick-dots li {
  padding: 0 9px;
}

.slick-dots li button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  padding: 0;
  font-size: 0;
  outline: none;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  transition: border-color 0.3s;
}

.slick-dots li button:before {
  content: "";
  display: block;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
}

.slick-dots li.slick-active button {
  border-color: #fff;
}

.sales {
  display: flex;
  margin-bottom: 42px;
}

@media (max-width: 1300px) {
  .sales {
    flex-wrap: wrap;
  }
}

.sales__item {
  width: 25%;
}

@media (max-width: 1300px) {
  .sales__item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .sales__item {
    width: 100%;
  }
}

.sale {
  position: relative;
  background-position: center;
  background-size: cover;
}

.sale__left-side {
  height: 100%;
  min-height: 300px;
  background: rgba(0, 0, 0, 0.4);
  width: 250px;
  padding: 41px 10px 33px;
}

.sale__sale-text-wrapp {
  width: 240px;
  position: relative;
  left: 30%;
  margin-bottom: 17px;
}

.sale__sale-text {
  font-size: 64px;
  color: #fff;
  font-family: "Musbold", sans-serif;
  line-height: 77px;
  position: relative;
  z-index: 2;
}

.sale__sale-line {
  position: absolute;
  bottom: 0;
  left: -10px;
}

.sale__text {
  font-size: 16px;
  color: #fff;
}

.sale__button {
  position: absolute;
  bottom: 33px;
  right: 27px;
}

.products-wrapp {
  padding-top: 8px;
  position: relative;
  overflow: hidden;
}

.products-wrapp__title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 31px;
}

.products {
  position: relative;
  display: flex;
  width: calc(100% + 32px);
  transform: translateX(-16px);
}

@media (max-width: 1199px) {
  .products {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .products {
    width: 100%;
    transform: none;
  }
}

.products__item {
  padding: 0 16px;
  margin-bottom: 32px;
  width: 25%;
}

@media (max-width: 1199px) {
  .products__item {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .products__item {
    width: 100%;
    padding: 0 15px;
  }
}

.products--product_page {
  flex-wrap: wrap;
}

.products--product_page .products__item {
  width: 33.33%;
}

@media (max-width: 1400px) {
  .products--product_page .products__item {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .products--product_page .products__item {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .products--product_page .products__item {
    padding: 0;
  }
}

.products--horizontal .products__item {
  width: 100%;
}

.product {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product__top {
  margin-bottom: auto;
  padding-bottom: 14px;
}

.product__bott {
  margin-top: auto;
  padding-top: 18px;
}

.product__name {
  display: block;
  text-align: center;
  font-size: 18px;
  color: #606060;
  padding: 0 20px;
}

@media (max-width: 1400px) {
  .product__name {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .product__name {
    padding: 0;
  }
}

.product__mid {
  height: 230px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.product__bott-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;
}

.product__cost {
  font-size: 14px;
  padding-right: 10px;
}

.product__cost-val {
  font-size: 24px;
  color: #606060;
  font-family: "Musbold", sans-serif;
  padding: 0 4px 0 12px;
}

@media (max-width: 1500px) {
  .product__cost-val {
    padding-left: 0;
  }
}

@media (max-width: 1500px) {
  .product__cost-text {
    display: block;
  }
}

.product__button {
  justify-content: center;
}

.product .select2-selection__rendered {
  background: #EFEFEF;
  border-radius: 100px;
}

.product .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 24px;
  padding-right: 45px;
}

@media (max-width: 1650px) {
  .product .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 14px;
  }
}

.product .select2-container {
  width: 150px !important;
}

@media (max-width: 1650px) {
  .product .select2-container {
    width: 110px !important;
  }
}

.product .select2-container *:focus {
  outline: none;
}

.product .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 8px;
}

.product .select2-container--default .select2-selection--single .select2-selection__arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: -8px;
  top: 6px;
  height: 15px;
  width: 1px;
  background: #888;
}

@media (min-width: 1200px) {
  .product--horizontal {
    flex-direction: row;
    align-items: center;
    padding: 24px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .product--horizontal {
    flex-direction: row;
    align-items: center;
    padding: 12px;
  }
}

@media (min-width: 992px) {
  .product--horizontal .product__top {
    margin-bottom: 0;
    order: 1;
    max-width: 600px;
    padding-left: 15px;
    padding-right: 20px;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .product--horizontal .product__name {
    text-align: left;
    padding: 0;
  }
}

@media (min-width: 1601px) {
  .product--horizontal .product__bott {
    margin-top: 0;
    order: 2;
    padding-top: 0;
    display: flex;
    margin-left: auto;
    align-items: center;
    flex-shrink: 0;
  }
}

@media (max-width: 1600px) and (min-width: 992px) {
  .product--horizontal .product__bott {
    display: block;
    margin-top: 0;
    order: 2;
    padding-top: 0;
    margin-left: auto;
  }
}

@media (min-width: 1601px) {
  .product--horizontal .product__bott-flex {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

@media (max-width: 1600px) and (min-width: 992px) {
  .product--horizontal .product__bott-flex {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .product--horizontal .product__cost {
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .product--horizontal .product__mid {
    width: 150px;
    height: 87px;
    flex-shrink: 0;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .product--horizontal .product__mid {
    width: 110px;
    height: 87px;
    flex-shrink: 0;
  }
}

.ad-wrapper {
  margin-bottom: 10px;
}

.ad {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@media (max-width: 767px) {
  .ad {
    flex-wrap: wrap;
  }
}

.ad__item {
  width: 250px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .ad__item {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .ad__item {
    width: 100%;
    padding: 0 15px;
  }
}

.ad__img {
  margin: 0 auto 24px auto;
}

.ad__title {
  font-size: 18px;
  text-align: center;
  font-family: "Musbold", sans-serif;
  margin-bottom: 8px;
}

@media (max-width: 991px) {
  .ad__title {
    font-size: 16px;
  }
}

.ad__text {
  font-size: 18px;
  text-align: center;
}

@media (max-width: 991px) {
  .ad__text {
    font-size: 14px;
  }
}

.index-catalog-wrapp {
  margin-bottom: 40px;
}

.index-catalog-wrapp__title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.index-catalog {
  display: flex;
}

@media (max-width: 767px) {
  .index-catalog {
    display: block;
  }
}

.index-catalog__item {
  width: 50%;
}

@media (max-width: 767px) {
  .index-catalog__item {
    width: 100%;
  }
}

.index-catalog__item--left {
  padding-right: 16px;
}

@media (max-width: 767px) {
  .index-catalog__item--left {
    padding: 0;
  }
}

.index-catalog__item--right {
  padding-left: 16px;
}

@media (max-width: 767px) {
  .index-catalog__item--right {
    padding: 0;
  }
}

.price-block {
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 300px;
  padding: 54px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-block__title {
  font-size: 24px;
  max-width: 400px;
  margin: 0 auto 32px auto;
  text-align: center;
}

.price-block__title--less-margin {
  margin-bottom: 9px;
}

.price-block__text {
  font-size: 18px;
  max-width: 400px;
  text-align: center;
  margin-bottom: 20px;
}

.price-block__button {
  margin-top: auto;
}

.price-block__rombus-title {
  line-height: 43px;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.rhombus-wr {
  position: relative;
}

.rhombus-wr:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 35px);
  width: 95px;
  height: 2px;
  background: #606060;
}

.rhombus-wr:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 35px);
  width: 95px;
  height: 2px;
  background: #606060;
}

.rhombus-wr__rhombus {
  width: 14px;
  height: 14px;
  background: #606060;
  transform-origin: center;
  transform: rotate(45deg);
}

.search-market {
  margin-bottom: 26px;
  background: linear-gradient(257.11deg, #D7D8DC -9.06%, #EAEBEF 51.84%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.search-market__bg-images {
  padding: 55px 0 45px 0;
  background-image: url(../img/search-bg-1.jpg), url(../img/search-bg-2.jpg);
  background-position: top left, top right;
  background-repeat: no-repeat;
}

@media (max-width: 1500px) {
  .search-market__bg-images {
    background-position: top left -150px, top right;
  }
}

@media (max-width: 1199px) {
  .search-market__bg-images {
    background-position: top left -250px, top right;
  }
}

@media (max-width: 991px) {
  .search-market__bg-images {
    background-image: none;
  }
}

.search-market__wrapp {
  width: 100%;
  max-width: 905px;
  padding: 0 20px;
  margin: 0 auto;
}

.search-market__title {
  font-size: 36px;
  margin-bottom: 8px;
  line-height: 43px;
  color: #606060;
}

.search-market__sub-title {
  font-size: 18px;
  margin-bottom: 24px;
  color: #606060;
}

.search-market__input {
  display: block;
  width: 100%;
  border: none;
  padding: 11px 24px;
  font-size: 18px;
  background: #fff;
  margin-bottom: 31px;
}

.search-market__input::-webkit-input-placeholder {
  color: #C4C4C4;
}

.search-market__input::-moz-placeholder {
  color: #C4C4C4;
}

.search-market__input:-moz-placeholder {
  color: #C4C4C4;
}

.search-market__input:-ms-input-placeholder {
  color: #C4C4C4;
}

.search-market__button {
  justify-content: flex-end;
}

.ad-two-wrapper {
  margin-bottom: 31px;
  background: #fff;
  padding: 20px 0 15px;
}

.ad-two {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@media (max-width: 767px) {
  .ad-two {
    flex-wrap: wrap;
  }
}

.ad-two__item {
  width: 200px;
}

@media (max-width: 991px) {
  .ad-two__item {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .ad-two__item {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 15px;
  }
}

.ad-two__img {
  margin: 0 auto 16px auto;
}

.ad-two__title {
  font-size: 18px;
  text-align: center;
  font-family: "Musbold", sans-serif;
}

@media (max-width: 991px) {
  .ad-two__title {
    font-size: 16px;
  }
}

.footer-wrapp {
  background: #463C3D;
  padding: 40px 0 18px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .footer {
    flex-wrap: wrap;
  }
}

.footer__side {
  padding-right: 30px;
}

.footer__side:last-child {
  padding-right: 0;
}

.footer__side--logo {
  width: 25%;
}

@media (max-width: 1199px) {
  .footer__side--logo {
    width: 50%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .footer__side--logo {
    width: 100%;
  }
}

.footer__side--menu {
  width: 35%;
}

@media (max-width: 1199px) {
  .footer__side--menu {
    width: 50%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .footer__side--menu {
    width: 100%;
  }
}

.footer__side--form {
  width: 40%;
}

@media (max-width: 1199px) {
  .footer__side--form {
    width: 100%;
  }
}

.footer-left-side__logo {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.footer-left-side__logo-img {
  flex-shrink: 0;
  margin-right: 5px;
}

.footer-left-side__logo-text {
  font-size: 24px;
  color: #fff;
  font-family: "Musbold", sans-serif;
}

.footer-left-side__link-wr {
  display: flex;
}

.footer-left-side__tel {
  display: block;
  font-size: 24px;
  font-family: "Musbold", sans-serif;
  color: #fff;
  transition: color 0.3s;
}

.footer-left-side__tel:hover {
  color: #F1A7B6;
}

.footer-left-side__tel-text {
  font-size: 16px;
  color: #fff;
  margin-bottom: 24px;
}

.footer-left-side__mail {
  display: block;
  font-size: 16px;
  color: #fff;
  margin-bottom: 19px;
  transition: color 0.3s;
}

.footer-left-side__mail:hover {
  color: #F1A7B6;
}

.footer-menu__title {
  font-size: 24px;
  margin-bottom: 24px;
  color: #fff;
  font-family: "Musbold", sans-serif;
}

.footer-menu__lists {
  display: flex;
}

.footer-menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 50%;
  padding-right: 20px;
}

.footer-menu__list:last-child {
  padding-right: 0;
}

.footer-menu__list-item {
  margin-bottom: 16px;
}

.footer-menu__list-link {
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
}

.footer-menu__list-link:hover {
  color: #F1A7B6;
}

.footer-form__title {
  font-size: 24px;
  margin-bottom: 24px;
  color: #fff;
  font-family: "Musbold", sans-serif;
}

.footer-form__form {
  display: flex;
  margin-bottom: 34px;
}

.footer-form__input {
  width: 100%;
  padding: 0 32px;
  border: none;
  background: #fff;
  display: block;
  font-size: 16px;
  outline: none;
}

.footer-form__input::-webkit-input-placeholder {
  color: #C4C4C4;
}

.footer-form__input::-moz-placeholder {
  color: #C4C4C4;
}

.footer-form__input:-moz-placeholder {
  color: #C4C4C4;
}

.footer-form__input:-ms-input-placeholder {
  color: #C4C4C4;
}

.footer-form__button {
  display: block;
  width: 203px;
  flex-shrink: 0;
  border: none;
  background: #F1A7B6;
  height: 52px;
  color: #fff;
  outline: none;
  transition: color 0.3s, background-color 0.3s;
}

@media (max-width: 767px) {
  .footer-form__button {
    width: 100px;
  }
}

.footer-socials {
  display: flex;
  align-items: center;
}

.footer-socials__item {
  padding-right: 40px;
}

.footer-socials__item path {
  transition: fill 0.3s;
}

.footer-socials__item:hover path {
  fill: #F1A7B6;
}

.copyright {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .copyright {
    display: block;
  }
}

.copyright__side {
  display: flex;
}

@media (max-width: 991px) {
  .copyright__side {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .copyright__side {
    display: block;
  }
}

@media (max-width: 991px) {
  .copyright__side:last-child {
    margin-bottom: 0;
  }
}

.copyright__link {
  display: block;
  margin-right: 70px;
  font-size: 16px;
  color: #000;
  transition: color 0.3s;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .copyright__link {
    margin-right: 30px;
  }
}

.copyright__link:hover {
  color: #F1A7B6;
}

.copyright__link:last-child {
  margin-right: 0;
}

.copyright__text {
  margin-right: 70px;
  font-size: 16px;
  color: #000;
}

@media (max-width: 1199px) {
  .copyright__text {
    margin-right: 30px;
  }
}

.copyright__text:last-child {
  margin-right: 0;
}

.copyright-wrapp {
  padding: 32px 0;
}

@media (max-width: 991px) {
  .copyright-wrapp {
    padding: 16px 0;
  }
}

.breads {
  padding: 50px 0 32px;
}

@media (max-width: 991px) {
  .breads {
    padding: 30px 0;
  }
}

.breads__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.breads__item {
  position: relative;
  margin-right: 24px;
}

.breads__item:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: calc(100% + 12px);
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background: #F1A7B6;
  border-radius: 50%;
}

.breads__item:last-child:before {
  display: none;
}

.breads__link {
  font-size: 14px;
  color: #606060;
}

.catalog-wrapp__title {
  font-size: 36px;
  margin-bottom: 31px;
  text-align: center;
  color: #606060;
}

@media (max-width: 991px) {
  .catalog-wrapp__title {
    font-size: 24px;
  }
}

.catalog {
  display: flex;
}

@media (max-width: 767px) {
  .catalog {
    display: block;
  }
}

.catalog__filter {
  width: 415px;
  flex-shrink: 0;
}

@media (max-width: 1366px) {
  .catalog__filter {
    width: 350px;
  }
}

@media (max-width: 1199px) {
  .catalog__filter {
    width: 290px;
  }
}

@media (max-width: 767px) {
  .catalog__filter {
    width: 270px;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    transform: translateX(-100%);
    transition: transform 0.3s;
    z-index: 10;
    height: 100%;
    overflow: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }
}

.catalog__filter--visible {
  transform: translate(0);
}

.catalog__content {
  width: 100%;
  padding-left: 32px;
}

@media (max-width: 767px) {
  .catalog__content {
    padding-left: 0;
  }
}

.catalog__pagination {
  padding-top: 30px;
  margin-bottom: 60px;
}

.catalog__xs-title-filter {
  display: none;
}

@media (max-width: 767px) {
  .catalog__xs-title-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
  }
}

@media (max-width: 767px) {
  .catalog__xs-title-filter-text {
    font-size: 24px;
  }
}

.catalog__xs-close-filter {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
}

.catalog__xs-close-filter:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background: #000;
  transform-origin: center;
  transform: translateX(-50%) rotate(45deg);
}

.catalog__xs-close-filter:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background: #000;
  transform-origin: center;
  transform: translateY(-50%) rotate(45deg);
}

.xs-filter-button {
  display: none;
}

@media (max-width: 767px) {
  .xs-filter-button {
    display: flex;
    align-items: center;
    background: #fff;
    border: none;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 5px 15px;
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .xs-filter-button__img {
    width: 15px;
    margin-right: 10px;
    font-size: 16px;
    display: block;
  }
}

.catalog-view {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 32px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .catalog-view {
    display: block;
  }
}

.catalog-view__left {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .catalog-view__left {
    margin-bottom: 7px;
  }
}

@media (max-width: 767px) {
  .catalog-view__left {
    display: block;
  }
}

.catalog-view__right {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .catalog-view__right .select2 {
    position: relative;
    left: -8px;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .catalog-view__right .select2 {
    position: relative;
    left: 0;
  }
}

.catalog-view__sort-text {
  font-size: 18px;
  color: #606060;
}

@media (max-width: 767px) {
  .catalog-view__sort-text {
    margin-bottom: 7px;
  }
}

.catalog-view__icon {
  margin-left: 14px;
  position: relative;
  top: 3px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .catalog-view__icon {
    display: none;
  }
}

.catalog-view__icon path {
  transition: fill 0.3s;
}

.catalog-view__icon:hover path {
  fill: #F1A7B6;
}

.catalog-view .select2-container {
  width: auto !important;
  min-width: 180px;
}

.catalog-view .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 18px;
  color: #606060;
}

.filter {
  margin-bottom: 32px;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .filter {
    padding: 24px;
  }
}

@media (max-width: 767px) {
  .filter {
    box-shadow: none;
    margin-bottom: 0;
    padding: 24px 16px;
  }
}

@media (max-width: 1199px) {
  .filter__range-slider {
    padding: 0 8px;
  }
}

@media (max-width: 767px) {
  .filter__range-slider {
    padding: 0 20px;
  }
}

.filter__price-title {
  font-size: 18px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .filter__price-title {
    font-size: 24px;
  }
}

.filter__groupe {
  margin-bottom: 24px;
}

.filter__title-groupe {
  font-size: 24px;
  margin-bottom: 14px;
}

.filter__check-box {
  margin-bottom: 12px;
}

.filter__hidden-checkboxes {
  display: none;
}

.filter__hidden-checkboxes--visible {
  display: block;
}

.filter__button-show {
  display: block;
  border: none;
  background: transparent;
  color: #F1A7B6;
  position: relative;
  padding: 0;
  padding-right: 23px;
}

.filter__button-show:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #F1A7B6 transparent transparent transparent;
}

.filter__button-show--hidden {
  display: none;
}

.filter__buttons-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter__button-refresh {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #606060;
  font-size: 14px;
  margin-bottom: 16px;
  transition: 0.3s;
}

.filter__button-refresh:hover {
  color: #F1A7B6;
}

.filter__button-refresh:hover svg path {
  fill: #F1A7B6;
}

.filter__button-refresh svg {
  display: block;
  margin-right: 5px;
}

.filter__button-refresh svg path {
  transition: fill 0.3s;
}

.filter .irs-line {
  height: 3px;
  background: #EFEFEF;
}

.filter .irs-bar {
  background-color: #F1A7B6;
  height: 3px;
}

.filter .irs-from,
.filter .irs-to,
.filter .irs-single {
  top: -5px;
  font-size: 16px;
  background: transparent;
  color: #606060;
}

.filter .irs-from:after,
.filter .irs-to:after,
.filter .irs-single:after {
  display: none;
}

.filter .irs-slider {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #F1A7B6;
  border: 1px solid #fff;
  cursor: pointer;
  top: 19px;
}

.filter--less-distance-bott {
  padding-bottom: 15px;
}

.one-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.one-checkbox__checkbox {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: -5000px;
  top: 0;
}

.one-checkbox label {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #F1A7B6;
  position: relative;
  top: -1px;
  cursor: pointer;
  margin-bottom: 0;
}

.one-checkbox label:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: #F1A7B6;
  opacity: 0;
}

.one-checkbox__checkbox:checked + label:before {
  opacity: 1;
}

.one-checkbox__label {
  padding-left: 16px;
  font-size: 18px;
  color: #606060;
}

.one-checkbox--radio label {
  border-radius: 50%;
}

.one-checkbox--radio label:before {
  border-radius: 50%;
}

.pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

.pagination-list__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 26px;
  font-size: 14px;
  transition: color 0.3s;
  color: #1E2022;
  position: relative;
}

.pagination-list__link:hover {
  color: #F1A7B6;
}

.pagination-list__link--prev {
  width: auto;
  margin-right: 15px;
  padding-left: 26px;
}

@media (max-width: 767px) {
  .pagination-list__link--prev {
    padding-left: 16px;
  }
}

.pagination-list__link--prev:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #F1A7B6 transparent transparent;
}

.pagination-list__link--next {
  width: auto;
  margin-left: 15px;
  padding-right: 26px;
}

@media (max-width: 767px) {
  .pagination-list__link--next {
    padding-right: 16px;
  }
}

.pagination-list__link--next:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #F1A7B6;
}

.pagination-list__link--active {
  color: #fff;
  background-color: #F1A7B6;
}

.pagination-list__link--active:hover {
  color: #fff;
}

.product-page__product-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.product-layout {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1199px) {
  .product-layout {
    display: block;
  }
}

.product-layout__left {
  width: 70%;
}

@media (max-width: 1199px) {
  .product-layout__left {
    width: 100%;
    margin-bottom: 32px;
  }
}

.product-layout__right {
  width: 30%;
  padding-left: 32px;
}

@media (max-width: 1199px) {
  .product-layout__right {
    width: 100%;
    padding-left: 0;
  }
}

.product-info {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 40px 25px 14px 20px;
}

@media (max-width: 1199px) {
  .product-info {
    padding: 30px 20px 20px;
  }
}

@media (max-width: 767px) {
  .product-info {
    padding: 30px 15px 20px;
    display: block;
  }
}

.product-info__left {
  width: 330px;
  flex-shrink: 0;
}

@media (max-width: 1366px) {
  .product-info__left {
    width: 290px;
  }
}

@media (max-width: 767px) {
  .product-info__left {
    width: 100%;
    margin: 0 auto;
  }
}

.product-info__right {
  width: 100%;
  padding-left: 50px;
}

@media (max-width: 1366px) {
  .product-info__right {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .product-info__right {
    padding-left: 0;
  }
}

.product-info__stars {
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .product-info__img-wrapp {
    margin-bottom: 15px;
  }
}

.product-info__img {
  object-fit: contain;
  margin: 0 auto;
  height: 322px;
}

@media (max-width: 1366px) {
  .product-info__img {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .product-info__img {
    height: 240px;
  }
}

.stars {
  display: flex;
  align-items: center;
}

.stars__star {
  margin-right: 3px;
}

.stars__star:last-child {
  margin-right: 0;
}

.product-description__title {
  font-size: 24px;
  margin-bottom: 16px;
  line-height: 29px;
}

@media (max-width: 767px) {
  .product-description__title {
    font-size: 18px;
    line-height: normal;
  }
}

.product-description__article {
  font-size: 18px;
  color: #606060;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .product-description__article {
    font-size: 16px;
  }
}

.product-description__other-descr-wr {
  margin-bottom: 24px;
}

.product-description__other-descr {
  font-size: 16px;
}

.product-description__text {
  font-size: 18px;
  color: #606060;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .product-description__text {
    font-size: 16px;
  }
}

.product-description__text span {
  display: block;
  margin-bottom: 16px;
  color: #000;
}

.product-description__stock {
  font-size: 18px;
  padding-left: 26px;
  position: relative;
  margin-bottom: 16px;
}

.product-description__stock::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #10C200;
}

.product-description__stock--sold-out::before {
  background: #EE0030;
}

.product-description__count-text {
  font-size: 14px;
  margin-bottom: 6px;
}

.product-description__count-price {
  margin-bottom: 16px;
  max-width: 400px;
  width: 100%;
}

.product-description__basket-add-wr {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .product-description__basket-add-wr {
    justify-content: center;
  }
}

.product-description__basket-add {
  display: block;
  position: relative;
  border: none;
  background-color: #F1A7B6;
  padding: 13px 108px 13px 32px;
  text-align: left;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.15em;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .product-description__basket-add {
    padding: 13px 32px 13px 32px;
  }
}

.product-description__basket-add svg {
  display: block;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .product-description__basket-add svg {
    display: none;
  }
}

.product-description__basket-add svg path {
  transition: fill 0.3s;
}

.product-description__basket-add:hover {
  color: #606060;
  background-color: #fff;
}

.product-description__basket-add:hover svg path {
  fill: #F1A7B6;
}

.count-and-price {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .count-and-price {
    display: block;
  }
}

.count-and-price__counter {
  width: 150px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  border-radius: 100px;
  background: #EFEFEF;
  overflow: hidden;
}

@media (max-width: 767px) {
  .count-and-price__counter {
    margin-bottom: 15px;
  }
}

.count-and-price__counter-action {
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  position: relative;
  outline: none;
}

.count-and-price__counter-action:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
}

.count-and-price__counter-action--less:before {
  border-width: 6px 6px 0 6px;
  border-color: #606060 transparent transparent transparent;
}

.count-and-price__counter-action--more:before {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #606060 transparent;
}

.count-and-price__count {
  width: 40px;
  flex-shrink: 0;
  color: #606060;
  font-size: 14px;
  text-align: center;
  position: relative;
  padding: 8px 0;
}

.count-and-price__count:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 15px;
  background: #C4C4C4;
}

.count-and-price__count:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 15px;
  background: #C4C4C4;
}

.count-and-price__price {
  font-size: 14px;
}

.count-and-price__price-value {
  color: #606060;
  font-size: 24px;
  font-family: "Musbold", sans-serif;
  padding-left: 12px;
}

.product-offer {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 40px 20px 20px 24px;
}

@media (max-width: 1199px) {
  .product-offer {
    padding: 30px 20px 20px;
  }
}

@media (max-width: 767px) {
  .product-offer {
    padding: 30px 15px 20px;
  }
}

.product-offer__title {
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 29px;
}

.product-offer__offer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-offer__offer-img {
  flex-shrink: 0;
}

.product-offer__offer-text {
  width: 100%;
  padding-left: 20px;
  color: #606060;
  font-size: 16px;
}

.product-details {
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
}

.product-details__wrapp {
  padding: 24px 20px 34px 20px;
  max-width: 1920px;
}

@media (max-width: 767px) {
  .product-details__wrapp {
    padding: 24px 15px 30px;
  }
}

.product-details .nav-tabs {
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 15px;
  min-height: 43px;
}

.product-details .nav-tabs > li {
  float: none;
  margin: 0 50px 0 0;
}

.product-details .nav-tabs > li.active > a, .product-details .nav-tabs > li.active > a:hover, .product-details .nav-tabs > li.active > a:focus {
  border: none;
  color: #000;
  border-bottom: 1px solid #F1A7B6;
  font-size: 24px;
}

.product-details .nav > li > a:hover, .product-details .nav > li > a:focus {
  background: transparent;
}

.product-details .nav-tabs > li > a {
  padding: 4px 0;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: none;
  margin: 0;
  font-size: 18px;
  color: #000;
  transition: font-size 0.3s;
}

.description-tab {
  max-width: 1340px;
}

.description-tab__text {
  color: #606060;
  font-size: 18px;
  margin-bottom: 24px;
}

.description-tab__text:last-child {
  margin-bottom: 0;
}

.description-tab__title {
  color: #606060;
  font-size: 18px;
  margin-bottom: 16px;
  font-family: "Musbold", sans-serif;
}

.reviews-tab {
  max-width: 1300px;
  padding-top: 20px;
}

.reviews-tab__review {
  margin-bottom: 40px;
}

.reviews-tab__button {
  justify-content: center;
}

.review-product {
  display: flex;
}

@media (max-width: 767px) {
  .review-product {
    display: block;
  }
}

.review-product__ava {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  border: 5px solid #F1A7B6;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  .review-product__ava {
    margin: 0 auto 20px auto;
  }
}

.review-product__review {
  width: 100%;
  padding-left: 30px;
}

@media (max-width: 767px) {
  .review-product__review {
    padding-left: 0;
  }
}

.review-product__name {
  font-size: 18px;
  font-family: "Musbold", sans-serif;
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .review-product__name {
    text-align: center;
  }
}

.review-product__stars {
  margin-bottom: 10px;
}

.review-product__text {
  color: #606060;
  font-size: 18px;
}

.basket-page__title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
}

.basket-list {
  margin-bottom: 26px;
}

.basket-list__item {
  margin-bottom: 24px;
}

.basket-item {
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 16px 20px;
}

@media (max-width: 767px) {
  .basket-item {
    padding: 16px 15px;
  }
}

.basket-item__wrapp {
  display: flex;
  align-items: center;
  max-width: 1326px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .basket-item__wrapp {
    flex-wrap: wrap;
    position: relative;
  }
}

.basket-item__flex-col {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .basket-item__flex-col {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .basket-item__flex-col {
    flex-wrap: wrap;
  }
}

.basket-item__flex-col:last-child {
  margin-left: auto;
}

@media (max-width: 991px) {
  .basket-item__flex-col:last-child {
    margin-bottom: 0;
  }
}

.basket-item__check-box {
  margin-right: 15px;
  flex-shrink: 0;
  width: 17px;
  height: 17px;
}

.basket-item__image {
  width: 100px;
  height: 87px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .basket-item__image {
    position: relative;
    margin: 0 auto;
    left: -16px;
  }
}

.basket-item__name-wr {
  padding-left: 20px;
  padding-right: 15px;
  max-width: 700px;
  width: 100%;
}

@media (max-width: 991px) {
  .basket-item__name-wr {
    max-width: 100%;
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .basket-item__name-wr {
    padding: 0;
    padding-top: 15px;
  }
}

.basket-item__name {
  display: block;
  font-size: 18px;
  color: #000;
  line-height: 22px;
  margin-bottom: 11px;
}

.basket-item__info {
  color: #606060;
  font-size: 14px;
  margin-bottom: 12px;
}

.basket-item__info:last-child {
  margin-bottom: 0;
}

.basket-item__conter-price {
  width: 370px;
  flex-shrink: 0;
}

@media (max-width: 991px) {
  .basket-item__conter-price {
    width: 100%;
    justify-content: space-between;
  }
}

.basket-item__delete {
  display: block;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #000;
  margin-left: 12px;
  transition: color 0.3s;
}

@media (max-width: 991px) {
  .basket-item__delete {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.basket-item__delete:hover {
  color: #F1A7B6;
}

.basket-total__wrapp {
  display: flex;
  justify-content: flex-end;
  max-width: 1326px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .basket-total__wrapp {
    padding: 0 15px;
  }
}

.basket-total__text {
  color: #606060;
  font-size: 24px;
  font-family: "Musblack", sans-serif;
}

.order {
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 45px 20px;
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .order {
    padding: 30px 15px;
  }
}

.order__wrapp {
  max-width: 1326px;
  margin: 0 auto;
}

.order__title {
  margin-bottom: 16px;
  font-size: 24px;
}

.order__info-wrapp-1 {
  margin-bottom: 24px;
  max-width: 850px;
}

.order__info-text-1 {
  font-size: 14px;
  color: #606060;
  margin-bottom: 16px;
}

.order__bonus-text {
  font-size: 18px;
  margin-bottom: 6px;
}

.order__bonus-text span {
  font-family: "Musbold", sans-serif;
}

.order__bonus-form {
  display: flex;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .order__bonus-form {
    display: block;
  }
}

@media (max-width: 767px) {
  .order__bonus-button {
    justify-content: center;
  }
}

.order__input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background: #EFEFEF;
  padding: 11px 16px;
  font-size: 18px;
}

.order__input::-webkit-input-placeholder {
  color: #C4C4C4;
}

.order__input::-moz-placeholder {
  color: #C4C4C4;
}

.order__input:-moz-placeholder {
  color: #C4C4C4;
}

.order__input:-ms-input-placeholder {
  color: #C4C4C4;
}

.order__input--small {
  width: 210px;
  margin-right: 24px;
}

@media (max-width: 767px) {
  .order__input--small {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.order__info-text-2 {
  color: #EE0030;
  margin-bottom: 16px;
}

.order__datail-butt-wr {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.order__datail-butt-wr--in_row {
  margin-bottom: 0;
}

.order__datail-butt {
  display: block;
  position: relative;
  border: none;
  background-color: #F1A7B6;
  padding: 13px 88px 13px 32px;
  text-align: left;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.15em;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .order__datail-butt {
    padding: 13px 32px 13px 32px;
  }
}

.order__datail-butt svg {
  display: block;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .order__datail-butt svg {
    display: none;
  }
}

.order__datail-butt svg path {
  transition: fill 0.3s;
}

.order__datail-butt:hover {
  color: #606060;
  background-color: #fff;
}

.order__datail-butt:hover svg path {
  fill: #F1A7B6;
}

.order__info-text-3 {
  font-size: 16px;
}

.order__info-text-3--more-margin {
  margin-bottom: 36px;
}

.order__address {
  display: block;
  width: 100%;
  max-width: 1000px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #EFEFEF;
  padding: 8px 0;
  font-size: 14px;
  outline: none;
}

.order__address::-webkit-input-placeholder {
  color: #C4C4C4;
}

.order__address::-moz-placeholder {
  color: #C4C4C4;
}

.order__address:-moz-placeholder {
  color: #C4C4C4;
}

.order__address:-ms-input-placeholder {
  color: #C4C4C4;
}

.order__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 22px;
}

@media (max-width: 767px) {
  .order__footer {
    display: block;
  }
}

.order__total {
  color: #606060;
  font-size: 24px;
  font-family: "Musblack", sans-serif;
  margin-right: 43px;
}

@media (max-width: 767px) {
  .order__total {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.order-radio {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .order-radio {
    display: block;
    margin-bottom: 24px;
  }
}

.order-radio__item {
  margin-right: 80px;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  .order-radio__item {
    margin-right: 0;
    margin-bottom: 7px;
  }
}

.order-data-form {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .order-data-form {
    display: block;
  }
}

.order-data-form__half {
  width: 50%;
}

@media (max-width: 767px) {
  .order-data-form__half {
    width: 100%;
  }
}

.order-data-form__half:nth-child(odd) {
  padding-right: 16px;
}

@media (max-width: 767px) {
  .order-data-form__half:nth-child(odd) {
    padding: 0;
  }
}

.order-data-form__half:nth-child(even) {
  padding-left: 16px;
}

@media (max-width: 767px) {
  .order-data-form__half:nth-child(even) {
    padding: 0;
  }
}

.order-data-form__input {
  margin-bottom: 16px;
}
