@import "layout/var";

.header-search {
    position: relative;

    @media (max-width: 991px){
        display: flex;
    }

    @media (max-width: 767px){
        display: none;
    }
    
    &__search-icon {
        cursor: pointer;

        @media (max-width: 991px){
            margin-left: auto;
        }
    }

    &__form {
        position: absolute;
        top: -5px;
        right: 0;
        overflow: hidden;
        width: 0;
        transition: width 0.3s;
    }

    &__form-subm {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        left: -5000px;
        top: 0;
    }

    &__form-inp {
        display: block;
        width: 100%;
        background: transparent;
        padding: 3px 25px 3px 0;
        outline: none;
        border: none;
        border-bottom: 1px solid $pink
    }

    &__form-close {
        display: none;
        position: absolute;
        right: 0;
        top: 3px;
        width: 18px;
        height: 18px;
        border: none;
        outline: none;
        background: transparent;
        padding: 0;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 1px;
            background: $pink;
            transform-origin: center;
            transform: translateX(-50%) rotate(45deg);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1px;
            width: 100%;
            background: $pink;
            transform-origin: center;
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &--opened {
        .header-search__form {
            width: 200px;

            @media (max-width: 1199px){
                width: 150px;
            }

            @media (max-width: 991px){
                width: 200px;
            }
        }

        .header-search__search-icon {
            opacity: 0;
        }

        .header-search__form-close {
            display: block;
        }
    }
}