@import "layout/var";

.ad {
   display: flex;
   justify-content: space-between;
   align-items: baseline;

   @media (max-width: 767px){
      flex-wrap: wrap;
   }

   &__item {
      width: 250px;
      margin-bottom: 30px;

      @media (max-width: 991px){
         width: 170px;
      }

      @media (max-width: 767px){
         width: 100%;
         padding: 0 15px;
      }

      
   }

   &__img {
      margin: 0 auto 24px auto;
   }

   &__title {
      font-size: 18px;
      text-align: center;
      font-family: $mus700;
      margin-bottom: 8px;

      @media (max-width: 991px){
         font-size: 16px;
      }
   }

   &__text {
      font-size: 18px;
      text-align: center;

      @media (max-width: 991px){
         font-size: 14px;
      }
   }
}