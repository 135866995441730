@import "layout/var";

.navigation {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

    @media (max-width: 767px){
        box-shadow: none;
        position: static;
        top: 0;
    }

    &__tel {
        display: none;
    }
    

    &--fixed {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;

        @media (max-width: 767px){
            position: static;
        }

        .navigation__tel {
            display: block;

            @media (max-width: 991px){
                display: none;
            }
        }
    }
}