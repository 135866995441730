@import "layout/var";

.basket-item {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;

    @media (max-width: 767px){
        padding: 16px 15px;
    }

    &__wrapp {
        display: flex;
        align-items: center;
        max-width: 1326px;
        margin: 0 auto;

        @media (max-width: 991px){
            flex-wrap: wrap;
            position: relative;
        }
    }

    &__flex-col {
        display: flex;
        align-items: center;

        @media (max-width: 991px){
            width: 100%;
            margin-bottom: 20px;
        }

        @media (max-width: 767px){
            flex-wrap: wrap;
        }

        &:last-child {
            margin-left: auto;

            @media (max-width: 991px){
                margin-bottom: 0;
            }
        }
    }

    &__check-box {
        margin-right: 15px;
        flex-shrink: 0;
        width: 17px;
        height: 17px;
    }

    &__image {
        width: 100px;
        height: 87px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        flex-shrink: 0;

        @media (max-width: 767px){
            position: relative;
            margin: 0 auto;
            left: -16px;
        }
    }

    &__name-wr {
        padding-left: 20px;
        padding-right: 15px;
        max-width: 700px;
        width: 100%;

        @media (max-width: 991px){
            max-width: 100%;
            padding-right: 40px;
        }

        @media (max-width: 767px){
            padding: 0;
            padding-top: 15px;
        }
    }

    &__name {
        display: block;
        font-size: 18px;
        color: #000;
        line-height: 22px;
        margin-bottom: 11px;
    }

    &__info {
        color: #606060;
        font-size: 14px;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__conter-price {
        width: 370px;
        flex-shrink: 0;

        @media (max-width: 991px){
            width: 100%;
            justify-content: space-between;
        }
    }

    &__delete {
        display: block;
        border: none;
        background: transparent;
        font-size: 16px;
        color: #000;
        margin-left: 12px;
        transition: color 0.3s;

        @media (max-width: 991px){
            position: absolute;
            top: 0px;
            right: 0px;

        }

        &:hover {
            color: $pink;
        }
    }
}