@import "layout/var";

.baner {

    &__slide {
        outline: none;
        min-height: 700px;

        @media (max-width: 1300px){
            min-height: 500px;
        }
    }
}