@import "layout/var";

.catalog-wrapp {
    &__title {
        font-size: 36px;
        margin-bottom: 31px;
        text-align: center;
        color: #606060;

        @media (max-width: 991px){
            font-size: 24px;
        }
    }
}