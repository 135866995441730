@import "layout/var";

.menu-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 767px){
        display: block;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: 15px 0;
    }

    &__item {
        padding: 0 30px;

        @media (max-width: 1230px){
            padding: 0 20px;
        }

        @media (max-width: 1199px){
            padding: 0 15px;
        }

        @media (max-width: 767px){
            padding: 0;
            margin-bottom: 7px;
            text-align: center;
        }
    }

    &__link {
        font-size: 16px;
        color: #000;
        transition: color 0.3s;

        &:hover {
            color: $pink;
        }
    }
}