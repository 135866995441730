@import "layout/var";

.header-bottom {
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width: 767px){
        display: block;
        position: static;
    }

    &__menu {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding: 16px 0;

        @media (max-width: 767px){
            border: none;
            padding: 0;
            width: 100%;
        }
    }

    &__tel {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #000;
        transition: color 0.3s;

        &:hover {
            color: $pink;
        }
    }

    .navbar-toggle{
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 5px;

        .icon-bar{
            background: #000;
        }
    }
}