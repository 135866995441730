@import "layout/var";

.index-catalog-wrapp {
   margin-bottom: 40px;

   &__title {
       font-size: 36px;
       text-align: center;
       margin-bottom: 30px;
   }
}