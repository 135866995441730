@import "layout/var";

.ad-two {
   display: flex;
   justify-content: space-between;
   align-items: baseline;

   @media (max-width: 767px){
      flex-wrap: wrap;
   }

   &__item {
      width: 200px;

      @media (max-width: 991px){
         width: 170px;
      }

      @media (max-width: 767px){
         width: 50%;
         padding: 0 15px;
         margin-bottom: 15px;
      }
   }

   &__img {
      margin: 0 auto 16px auto;
   }

   &__title {
      font-size: 18px;
      text-align: center;
      font-family: $mus700;

      @media (max-width: 991px){
         font-size: 16px;
      }
   }
}