@import "layout/var";

.order {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 45px 20px;
    margin-bottom: 38px;

    @media (max-width: 767px){
        padding: 30px 15px;
    }

    &__wrapp {
        max-width: 1326px;
        margin: 0 auto;
    }

    &__title {
        margin-bottom: 16px;
        font-size: 24px;
    }

    &__info-wrapp-1 {
        margin-bottom: 24px;
        max-width: 850px;
    }

    &__info-text-1 {
        font-size: 14px;
        color: #606060;
        margin-bottom: 16px;
    }

    &__bonus-text {
        font-size: 18px;
        margin-bottom: 6px;
        span {
            font-family: $mus700;
        }
    }

    &__bonus-form {
        display: flex;
        margin-bottom: 16px;

        @media (max-width: 767px){
            display: block;
        }
    }

    &__bonus-button {
        @media (max-width: 767px){
            justify-content: center;
        }
    }

    &__input {
        border: none;
        outline: none;
        display: block;
        width: 100%;
        background: #EFEFEF;
        padding: 11px 16px;
        font-size: 18px;

        &::-webkit-input-placeholder {
            color:#C4C4C4;
        }

        &::-moz-placeholder {
            color:#C4C4C4;
        }

        &:-moz-placeholder {
            color:#C4C4C4;
        }

        &:-ms-input-placeholder {
            color:#C4C4C4;
        }
        

        &--small {
            width: 210px;
            margin-right: 24px;

            @media (max-width: 767px){
                width: 100%;
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
    }

    &__info-text-2 {
        color: #EE0030;
        margin-bottom: 16px;
    }

    &__datail-butt-wr {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        &--in_row {
            margin-bottom: 0;
        }
    }

    &__datail-butt {
        display: block;
        position: relative;
        border: none;
        background-color: $pink;
        padding: 13px 88px 13px 32px;
        text-align: left;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.15em;
        transition: background-color 0.3s, color 0.3s;
        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);

        @media (max-width: 767px){
            padding: 13px 32px 13px 32px;
        }
        
        svg {
            display: block;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);

            @media (max-width: 767px){
                display: none;
            }

            path {
                transition: fill 0.3s;
            }
        }

        &:hover {
            color: #606060;
            background-color: #fff;

            svg {
                path {
                    fill: $pink;
                }
            }
        }
    }

    &__info-text-3 {
        font-size: 16px;
        
        &--more-margin {
            margin-bottom: 36px;
        }
    }

    &__address {
        display: block;
        width: 100%;
        max-width: 1000px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #EFEFEF;
        padding: 8px 0;
        font-size: 14px;
        outline: none;

        &::-webkit-input-placeholder {
            color:#C4C4C4;
        }

        &::-moz-placeholder {
            color:#C4C4C4;
        }

        &:-moz-placeholder {
            color:#C4C4C4;
        }

        &:-ms-input-placeholder {
            color:#C4C4C4;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 22px;

        @media (max-width: 767px){
            display: block;
        }
    }

    &__total {
        color: #606060;
        font-size: 24px;
        font-family: $mus900;
        margin-right: 43px;

        @media (max-width: 767px){
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}