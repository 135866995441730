@import "layout/var";

.product-description {
    &__title {
        font-size: 24px;
        margin-bottom: 16px;
        line-height: 29px;

        @media (max-width: 767px){
            font-size: 18px;
            line-height: normal;
        }
    }

    &__article {
        font-size: 18px;
        color: #606060;
        margin-bottom: 8px;

        @media (max-width: 767px){
            font-size: 16px;
        }
    }

    &__other-descr-wr {
        margin-bottom: 24px;
    }

    &__other-descr {
        font-size: 16px;
    }

    &__text {
        font-size: 18px;
        color: #606060;
        margin-bottom: 32px;

        @media (max-width: 767px){
            font-size: 16px;
        }

        span {
            display: block;
            margin-bottom: 16px;
            color: #000;
        }
    }

    &__stock {
        font-size: 18px;
        padding-left: 26px;
        position: relative;
        margin-bottom: 16px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #10C200;
        }
        
        &--sold-out {
            &::before {
                background: #EE0030;
            }
        }
    }

    &__count-text {
        font-size: 14px;
        margin-bottom: 6px;
    }

    &__count-price {
        margin-bottom: 16px;
        max-width: 400px;
        width: 100%;
    }

    &__basket-add-wr {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 767px){
            justify-content: center;
        }
    }

    &__basket-add {
        display: block;
        position: relative;
        border: none;
        background-color: $pink;
        padding: 13px 108px 13px 32px;
        text-align: left;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.15em;
        transition: background-color 0.3s, color 0.3s;
        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);

        @media (max-width: 767px){
            padding: 13px 32px 13px 32px;
        }
        
        svg {
            display: block;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);

            @media (max-width: 767px){
                display: none;
            }

            path {
                transition: fill 0.3s;
            }
        }

        &:hover {
            color: #606060;
            background-color: #fff;

            svg {
                path {
                    fill: $pink;
                }
            }
        }
    }
}