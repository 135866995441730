@import "layout/var";

.catalog-view {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 32px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px){
        display: block;
    }

    &__left {
        display: flex;
        align-items: center;

        @media (max-width: 991px){
            margin-bottom: 7px;
        }

        @media (max-width: 767px){
            display: block;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        .select2 {
            @media (max-width: 991px){
                position: relative;
                left: -8px;
                margin-right: auto;
            }

            @media (max-width: 767px){
                position: relative;
                left: 0;
            }
        }
    }

    &__sort-text {
        font-size: 18px;
        color: #606060;

        @media (max-width: 767px){
            margin-bottom: 7px;
        }
    }

    &__icon {
        margin-left: 14px;
        position: relative;
        top: 3px;
        cursor: pointer;

        @media (max-width: 991px){
            display: none;
        }

        path {
            transition: fill 0.3s;
        }

        &:hover {
            path {
                fill: $pink;
            }
        }
    }

    .select2-container {
        width: auto!important;
        min-width: 180px;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        font-size: 18px;
        color: #606060;
    }
}