@import "layout/var";

.basket-total {
    &__wrapp {
        display: flex;
        justify-content: flex-end;
        max-width: 1326px;
        padding: 0 20px;
        margin: 0 auto;
        
        @media (max-width: 767px){
            padding: 0 15px;
        }
    }

    &__text {
        color: #606060;
        font-size: 24px;
        font-family: $mus900;
    }
}