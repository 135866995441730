@import "layout/var";

.products {
    position: relative;
    display: flex;
    width: calc(100% + 32px);
    transform: translateX(-16px);

    @media (max-width: 1199px){
        flex-wrap: wrap;
    }

    @media (max-width: 767px){
        width: 100%;
        transform: none;
    }

    &__item {
        padding: 0 16px;
        margin-bottom: 32px;
        width: 25%;

        @media (max-width: 1199px){
            width: 50%;
        }

        @media (max-width: 767px){
            width: 100%;
            padding: 0 15px;
        }
    }

    &--product_page {
        flex-wrap: wrap;

        .products__item {
            width: 33.33%;

            @media (max-width: 1400px){
                width: 50%;
            }

            @media (max-width: 991px){
                width: 100%;
            }

            @media (max-width: 767px){
                padding: 0;
            }
        }
    }

    &--horizontal {
        .products__item {
            width: 100%;
        }
    }
}