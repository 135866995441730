@import "layout/var";

.xs-filter-button {
    display: none;

    @media (max-width: 767px){
        display: flex;
        align-items: center;
        background: #fff;
        border: none;
        width: 100%;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        padding: 5px 15px;
        margin-bottom: 32px;
    }

    &__img {
        @media (max-width: 767px){
            width: 15px;
            margin-right: 10px;
            font-size: 16px;
            display: block;
        }
    }
}