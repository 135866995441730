@font-face {
    font-family: 'Muslight';
    src: url('../fonts/MuseoSansCyrl-300.eot');
    src: url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Musmed';
    src: url('../fonts/MuseoSansCyrl-500.eot');
    src: url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Musbold';
    src: url('../fonts/MuseoSansCyrl-700.eot');
    src: url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Musblack';
    src: url('../fonts/MuseoSansCyrl-900.eot');
    src: url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MuseoSansCyrl-900.woff') format('woff'),
    url('../fonts/MuseoSansCyrl-900.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
