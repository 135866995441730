@import "layout/var";

.product-layout {
    display: flex;
    align-items: flex-start;

    @media (max-width: 1199px){
        display: block;
    }

    &__left {
        width: 70%;

        @media (max-width: 1199px){
            width: 100%;
            margin-bottom: 32px;
        }
    }

    &__right {
        width: 30%;
        padding-left: 32px;

        @media (max-width: 1199px){
            width: 100%;
            padding-left: 0;
        }
    }
}