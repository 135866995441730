@import "layout/var";

.description-tab {
    max-width: 1340px;

    &__text {
        color: #606060;
        font-size: 18px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        color: #606060;
        font-size: 18px;
        margin-bottom: 16px;
        font-family: $mus700;
    }
}