@import "layout/var";

.footer-menu {
    &__title {
        font-size: 24px;
        margin-bottom: 24px;
        color: #fff;
        font-family: $mus700;
    }

    &__lists {
        display: flex;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 50%;
        padding-right: 20px;

        &:last-child {
            padding-right: 0;
        }
    }

    &__list-item {
        margin-bottom: 16px;
    }

    &__list-link {
        color: #fff;
        font-size: 16px;
        transition: color 0.3s;

        &:hover {
            color: $pink;
        }
    }
}