@import "layout/var";

.product {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__top {
        margin-bottom: auto;
        padding-bottom: 14px;
    }

    &__bott {
        margin-top: auto;
        padding-top: 18px;
    }

    &__name {
        display: block;
        text-align: center;
        font-size: 18px;
        color: #606060;
        padding: 0 20px;

        @media (max-width: 1400px){
            font-size: 16px;
        }

        @media (max-width: 767px){
            padding: 0;
        }
    }

    &__mid {
        height: 230px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__bott-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 31px;
    }

    &__cost {
        font-size: 14px;
        padding-right: 10px;
    }

    &__cost-val {
        font-size: 24px;
        color: #606060;
        font-family: $mus700;
        padding: 0 4px 0 12px;

        @media (max-width: 1500px){
            padding-left: 0;
        }
    }

    &__cost-text {
        @media (max-width: 1500px){
            display: block;
        }
    }

    &__button {
        justify-content: center;
    }

    .select2-selection__rendered {
        background: #EFEFEF;
        border-radius: 100px;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-left: 24px;
        padding-right: 45px;

        @media (max-width: 1650px){
            padding-left: 14px;
        }
    }
    .select2-container {
        width: 150px!important;

        @media (max-width: 1650px){
            width: 110px!important;
        }
    }

    .select2-container *:focus {
        outline: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: 8px;
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -8px;
            top: 6px;
            height: 15px;
            width: 1px;
            background: #888;
        }
    }

    &--horizontal {
        @media (min-width: 1200px){
            flex-direction: row;
            align-items: center;
            padding: 24px;
        }
        
        @media (max-width: 1199px) and (min-width: 992px){
            flex-direction: row;
            align-items: center;
            padding: 12px;
        }

        .product__top {
            @media (min-width: 992px){
                margin-bottom: 0;
                order: 1;
                max-width: 600px;
                padding-left: 15px;
                padding-right: 20px;
                padding-bottom: 0;
            }
        }
        
        .product__name {
            @media (min-width: 992px){
                text-align: left;
                padding: 0;
            }
        }

        .product__bott {
            @media (min-width: 1601px){
                margin-top: 0;
                order: 2;
                padding-top: 0;
                display: flex;
                margin-left: auto;
                align-items: center;
                flex-shrink: 0;
            }
            
            @media (max-width: 1600px) and (min-width: 992px) {
                display: block;
                margin-top: 0;
                order: 2;
                padding-top: 0;
                margin-left: auto;
            }
            
        }

        .product__bott-flex {
            @media (min-width: 1601px){
                margin-bottom: 0;
                margin-right: 20px;
            }

            @media (max-width: 1600px) and (min-width: 992px){
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        .product__cost {
            @media (min-width: 992px){
                padding-right: 20px;
            }
        }

        .product__mid {
            @media (min-width: 1200px){
                width: 150px;
                height: 87px;
                flex-shrink: 0;
            }

            @media (max-width: 1199px) and (min-width: 992px){
                width: 110px;
                height: 87px;
                flex-shrink: 0;
            }
        }
    }
}