@import "layout/var";

.button-wrapp {
    display: flex;

    &__button {
        border: none;
        display: block;
        position: relative;
        font-size: 16px;
        transition: color 0.3s, background-color 0.3s;
        text-align: left;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 23px;
            transform: translateY(-50%);
            height: 1px;
            width: 60px;
            transition: background-color 0.3s;
        }

        &--pink {
            padding: 14px 94px 14px 29px;
            color: #fff;
            background: $pink;
            box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);

            &:before {
                background: #fff
            }

            &:hover {
                color: #606060;
                background: #fff;

                &:before {
                    background: $pink;
                }
            }
        }

        &--white {
            padding: 16px 98px 16px 32px;
            color: #606060;
            background: #fff;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);

            &:before {
                background: $pink;
            }

            &:hover {
                background-color: $pink;
                color: #fff;

                &:before {
                    background-color: #fff;
                }
            }
        }

        &--less-shadow {
            box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
        }

        &--min-width {
            min-width: 231px;
        }
    }
}