@import "layout/var";

.reviews-tab {
    max-width: 1300px;
    padding-top: 20px;

    &__review {
        margin-bottom: 40px;
    }

    &__button {
        justify-content: center;
    }
}