@import "layout/var";

.catalog {
    display: flex;

    @media (max-width: 767px){
        display: block;
    }

    &__filter {
        width: 415px;
        flex-shrink: 0;

        @media (max-width: 1366px){
            width: 350px;
        }

        @media (max-width: 1199px){
            width: 290px;
        }

        @media (max-width: 767px){
            width: 270px;
            position: fixed;
            left: 0;
            top: 0;
            background: #fff;
            transform: translateX(-100%);
            transition: transform 0.3s;
            z-index: 10;
            height: 100%;
            overflow: auto;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

        }

        &--visible {
            transform: translate(0);
        }
    }

    &__content {
        width: 100%;
        padding-left: 32px;

        @media (max-width: 767px){
            padding-left: 0;
        }
    }

    &__pagination {
        padding-top: 30px;
        margin-bottom: 60px;
    }

    &__xs-title-filter {
        display: none;

        @media (max-width: 767px){
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 16px;
            
        }
    }

    &__xs-title-filter-text {
        @media (max-width: 767px){
            font-size: 24px;
        }
    }

    &__xs-close-filter {
        width: 20px;
        height: 20px;
        display: block;
        position: relative;
        border: none;
        background: transparent;
        padding: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 1px;
            background: #000;
            transform-origin: center;
            transform: translateX(-50%) rotate(45deg);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1px;
            width: 100%;
            background: #000;
            transform-origin: center;
            transform: translateY(-50%) rotate(45deg);
        }
    }

}