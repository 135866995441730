@import "layout/var";

.index-catalog {
    display: flex;

    @media (max-width: 767px){
        display: block;
    }

    &__item {
        width: 50%;

        @media (max-width: 767px){
            width: 100%;
        }
        
        &--left {
            padding-right: 16px;

            @media (max-width: 767px){
                padding: 0;
            }
        }

        &--right {
            padding-left: 16px;

            @media (max-width: 767px){
                padding: 0;
            }
        }
    }
}