@import "layout/var";

.slick-arrow {
    display: block;
    position: absolute;
    font-size: 0;
    background: transparent;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 24px;
    border: none;
    padding: 0;
    outline: none;
    z-index: 9;

    &:before {
        content: "";
        display: block;
        position: relative;
        width: 0;
        height: 0;
        border-style: solid;
    }


    &.slick-prev {
        left: 60px;

        @media (max-width: 1600px){
            left: 20px;
        }

        &:before {
            border-width: 12px 12px 12px 0;
            border-color: transparent #ffffff transparent transparent;
        }
    }

    &.slick-next {
        right: 60px;

        @media (max-width: 1600px){
            right: 20px;
        }

        &:before {
            border-width: 12px 0 12px 12px;
            border-color: transparent transparent transparent #ffffff;
        }
    }
}

.slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex!important;
    position: absolute;
    left: 50%;
    bottom: 78px;
    transform: translateX(-50%);
    z-index: 9;

    @media (max-width: 1300px){
        bottom: 40px;
    }

    @media (max-width: 991px){
        bottom: 20px;
    }

    li{
        padding: 0 9px;
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            padding: 0;
            font-size: 0;
            outline: none;
            border-radius: 50%;
            background: transparent;
            border: 1px solid transparent;
            position: relative;
            transition: border-color 0.3s;

            &:before {
                content: "";
                display: block;
                position: relative;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #fff;
            }

        }

        &.slick-active {
            button{
                border-color: #fff;
            }
        }
    }
}