@import "layout/var";

.logo {
    &__img {
        margin: 0 auto;
    }

    &__name {
        font-size: 24px;
        line-height: 29px;
        font-family: $mus700;
        color: $pink;
        text-align: center;
    }
}