@import "layout/var";

.product-info {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 40px 25px 14px 20px;

    @media (max-width: 1199px){
        padding: 30px 20px 20px;
    }

    @media (max-width: 767px){
        padding: 30px 15px 20px;
        display: block;
    }

    &__left {
        width: 330px;
        flex-shrink: 0;

        @media (max-width: 1366px){
            width: 290px;
        }

        @media (max-width: 767px){
            width: 100%;
            margin: 0 auto;
        }
    }

    &__right {
        width: 100%;
        padding-left: 50px;

        @media (max-width: 1366px){
            padding-left: 30px;
        }

        @media (max-width: 767px){
            padding-left: 0;
        }
    }

    &__stars {
        margin-bottom: 24px;
    }

    &__img-wrapp {
        @media (max-width: 767px){
            margin-bottom: 15px;
        }
    }

    &__img {
        object-fit: contain;
        margin: 0 auto;
        height: 322px;

        @media (max-width: 1366px){
            height: 280px;
        }

        @media (max-width: 767px){
            height: 240px;
        }
    }
}